* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	// font-family: "Silkscreen";

	word-break: keep-all;
}

body {
	font-family: sans-serif;
	background-color: #1d2630;
	background-image: url("./stardust.png");
	width: 100%;
	max-height: 100vh;
	// height: calc(var(--vh, 1vh) * 100);
	#root .App {
		width: 100%;
		// height: 100vh;
		// height: calc(var(--vh, 1vh) * 100);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	#button-area {
		button {
			font-family: "NanumSquareNeo" !important;
		}
		#lang {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr;
			column-gap: 4px;
		}
	}

	#info-text {
		font-family: "NanumSquareNeo" !important;
		font-size: 0.85em;
	}
	#generator {
		font-family: "NanumSquareNeo";
		width: 100%;
		// height: 100vh;
		// height: calc(var(--vh, 1vh) * 100);
		background-color: white;
		display: grid;
		grid-template-rows: 1fr 1fr auto;
		place-items: stretch;
		padding: 20px;

		#display {
			width: 90%;
			place-self: center;
			height: auto;
			aspect-ratio: 1;
			// border: 1px solid black;
			position: relative;
			.display-area {
				position: absolute;
				width: 100%;
				// animation: fade-in 1s;
				#type {
					z-index: 1;
				}
				#face {
					z-index: 2;
				}

				img {
					position: absolute;
					display: block;
					width: 100%;
					height: auto;
				}
			}
		}

		#select-area {
			padding: 20px 0;
			min-width: 0;
			min-height: 0;
			display: grid;
			grid-template-rows: 1fr;
			row-gap: 12px;
			.selector-area {
				position: relative;
				overflow-y: auto;
				overflow-x: hidden;
				animation: up 1s;
				overscroll-behavior: none;
			}

			.title-wrapper {
				padding-bottom: 16px;
				font-weight: 900;
				font-size: 20px;
				color: #073b4c;
				animation: fade-in 1s;
				position: sticky;
				top: 0;
				background-color: white;
				z-index: 100;
			}

			.subtitle {
				margin-top: 12px;
				font-weight: 900;
				font-size: 16px;
				color: #073b4c;
			}
		}

		#button-area {
			button {
				font-family: "NanumSquareNeo" !important;
			}
			&.button-2-grid {
				display: grid;
				grid-template-columns: 40px auto;
				column-gap: 12px;
			}
		}
	}

	#after-generation {
		animation: fade-in 1s;
		display: grid;
		grid-template-rows: auto 1fr auto;
		place-items: center;
		width: 100%;
		// height: 100vh;
		// height: calc(var(--vh, 1vh) * 100);
		background-color: white;
		padding: 20px;
		img {
			// display: block;
			width: 100%;
			height: auto;
		}

		#button-area {
			display: grid;

			row-gap: 12px;
			width: 100%;
		}
	}
}

#loading {
	// font-family: "Unica One";
	font-family: "NeoDunggeunmo";
	text-transform: uppercase;
	border-radius: 100%;
	// background-color: white;
	aspect-ratio: 1;
	width: 390px;

	color: white;
	.circles {
		position: absolute;
		//display: flex;

		* {
			border-radius: 100%;
			// position: absolute;
			aspect-ratio: 1;
		}
		#circle-1 {
			position: relative;
			width: 400px;
			background: linear-gradient(62deg, #c67700 0%, #fcff9e 100%);

			animation: spin 4s linear 6s infinite forwards;
		}

		#circle-2 {
			position: absolute;
			top: 25px;
			left: 25px;
			width: 350px;
			animation: spin 4s linear 8s infinite forwards;
			background: linear-gradient(135deg, #c2ffd8 10%, #465efb 100%);
		}
	}
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	text-align: center;

	#loading-flavor {
		min-height: 30px;
		font-size: 1.2em;
	}
	#loading-text {
		min-height: 40px;
		// width: 300px;

		font-size: 1.7em;
		z-index: 1000;
	}

	#intro-text {
		animation: fade-in 1s ease-in forwards;
		animation-delay: 5s;
		opacity: 0;
	}

	#loading-wrapper {
		animation: fade-in 1s ease-in forwards;
		animation-delay: 2s;
		opacity: 0;
	}
	#loading-button {
		margin: 20px auto 0;
		cursor: pointer;

		animation: pulse 1s forwards ease-in infinite;
	}
}

#lang-select {
	margin-top: 12px;
	color: white;
	input {
		position: fixed;
		opacity: 0;
		pointer-events: none;
	}

	label {
		margin: 8px;
		font-weight: 100;
		color: gray;
	}

	input[type="radio"]:checked + label {
		font-weight: bolder;
		color: white;
	}
}

@media screen and (min-width: 400px) {
	body {
		#generator,
		#after-generation {
			width: 360px;
			height: 640px !important;
			border: 2px solid black;
		}
	}
}

@keyframes up {
	0% {
		opacity: 0;
		top: 20px;
	}

	100% {
		opacity: 1;
		top: 0px;
	}
}

@keyframes fade-in {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes pulse {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}

	100% {
		opacity: 1;
	}
}

@keyframes spin {
	0% {
		transform: rotate(0);
	}
	50% {
		transform: rotate(359deg);
	}
	100% {
		transform: rotate(359deg);
	}
}
